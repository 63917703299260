import { APP } from '@/common/constants'

const routes = [{
  path: '/auth',
  name: APP.PAGES.AUTH,
  component: () => import('@/views/auth')
}, {
  path: '/*',
  component: () => import('@/layout'),
  children: [{
    path: '/',
    name: APP.PAGES.MAIN,
    component: () => import('@/views/main')
  }, {
    path: '/profile',
    name: APP.PAGES.PROFILE,
    component: () => import('@/views/profile')
  }, {
    path: '/profile/edit',
    name: APP.PAGES.PROFILE_EDIT,
    component: () => import('@/views/profile/_edit')
  }, {
    path: '/users/:userId',
    name: APP.PAGES.USER,
    component: () => import('@/views/users-management/_user')
  }, {
    path: '/users/groups/:groupId/users/edit',
    name: APP.PAGES.USER_GROUP_USERS_EDIT,
    component: () => import('@/views/users-management/_groups/_users')
  }, {
    path: '/users/groups/:groupId/projects/edit',
    name: APP.PAGES.USER_GROUP_PROJECTS_EDIT,
    component: () => import('@/views/users-management/_groups/_projects')
  }, {
    path: '/users/groups/:groupId/access/edit',
    name: APP.PAGES.USER_GROUP_ACCESS_EDIT,
    component: () => import('@/views/users-management/_groups/_access')
  }, {
    path: '/users',
    name: APP.PAGES.USERS_MANAGEMENT,
    component: () => import('@/views/users-management'),
    children: [{
      path: '',
      name: APP.PAGES.USERS,
      component: () => import('@/views/users-management/_users')
    }, {
      path: 'groups/:groupId',
      name: APP.PAGES.USER_GROUP,
      component: () => import('@/views/users-management/_groups'),
      redirect: { name: APP.PAGES.USER_GROUP_USERS },
      children: [{
        path: 'users',
        name: APP.PAGES.USER_GROUP_USERS,
        component: () => import('@/views/users-management/_groups')
      }, {
        path: 'projects',
        name: APP.PAGES.USER_GROUP_PROJECTS,
        component: () => import('@/views/users-management/_groups')
      }, {
        path: 'access',
        name: APP.PAGES.USER_GROUP_ACCESS,
        component: () => import('@/views/users-management/_groups')
      }]
    }]
  }, {
    path: '/p/:projectId/components/tags/:tagType/:tagId',
    name: APP.PAGES.TAG,
    component: () => import('@/views/project/_components/_tags/_id')
  }, {
    path: '/p/:projectId/notifications/templates/:templateType/:templateId',
    name: APP.PAGES.NOTIFICATION_TEMPLATE,
    component: () => import('@/views/project/_notifications/_templates/_id')
  }, {
    path: '/p/:projectId',
    name: APP.PAGES.PROJECT,
    component: () => import('@/views/project'),
    redirect: { name: APP.PAGES.DASHBOARDS },
    children: [{
      path: 'dashboards/:dashboardId?',
      name: APP.PAGES.DASHBOARDS,
      component: () => import('@/views/project/_dashboards')
    }, {
      path: 'components',
      name: APP.PAGES.COMPONENTS,
      component: () => import('@/views/project/_components'),
      redirect: { name: APP.PAGES.TAGS },
      children: [{
        path: 'tags',
        name: APP.PAGES.TAGS,
        component: () => import('@/views/project/_components/_tags')
      }, {
        path: 'gateways',
        name: APP.PAGES.GATEWAYS,
        component: () => import('@/views/project/_components/_gateways')
      }, {
        path: 'mqtt-gateways',
        name: APP.PAGES.MQTT_GATEWAYS,
        component: () => import('@/views/project/_components/_mqtt-gateways')
      }, {
        path: 'opc-servers',
        name: APP.PAGES.OPC_SERVERS,
        component: () => import('@/views/project/_components/_opcua')
      }]
    }, {
      path: 'scripts/:scriptId?',
      name: APP.PAGES.SCRIPTS,
      component: () => import('@/views/project/_scripts')
    }, {
      path: 'notifications',
      name: APP.PAGES.NOTIFICATIONS,
      component: () => import('@/views/project/_notifications'),
      redirect: { name: APP.PAGES.NOTIFICATION_LIST },
      children: [{
        path: '',
        name: APP.PAGES.NOTIFICATION_LIST,
        component: () => import('@/views/project/_notifications/_notifications')
      }, {
        path: 'templates',
        name: APP.PAGES.NOTIFICATION_TEMPLATES,
        component: () => import('@/views/project/_notifications/_templates')
      }]
    }, {
      path: 'history',
      name: APP.PAGES.HISTORY,
      component: () => import('@/views/project/_history')
    }, {
      path: 'settings',
      name: APP.PAGES.SETTINGS,
      component: () => import('@/views/project/_settings')
    }]
  }, {
    path: '/error/:type?',
    name: APP.PAGES.ERROR,
    component: () => import('@/views/error')
  }]
}]

export default routes
